import React from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import TabProducts from '../../../shared/components/molecules/tabProducts/tabProducts'
import './textContent.scss'

const TextContent = props => {
	const { data, menu, title, subtitle, slug } = props
	return (
		<article>
			<div className='f-head'>
				<h1>{title}</h1>
				<h2>{subtitle}</h2>
			</div>
			<TabProducts tabs={menu} slug={slug} />
			<div className='f-textcontent'>
				{data && documentToReactComponents(data)}
			</div>
		</article>
	)
}

TextContent.propTypes = {
	data: PropTypes.object.isRequired,
	menu: PropTypes.object.isRequired,
	slug: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
}

export default TextContent
