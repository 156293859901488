import React from 'react'
import TextContent from '../components/organisms/textContent/textContent'
import SEO from '../shared/components/seo'
import Layout from '../shared/components/organisms/layout/layout'
import useMobileMode from '../shared/hooks/useMobileMode'

const Terms = props => {
	const { isMobile, currentPage } = useMobileMode()
	const { menu, contenido, slug, titulo, subtitulo } = props.pageContext

	return (
		<Layout title={props.pageContext.metaTitulo} isMobile={isMobile} currentPage={currentPage}>
			<SEO
				title={props.pageContext.metaTitulo}
				description={props.pageContext.metaDescripcion? props.pageContext.metaDescripcion.metaDescripcion : ''}
				lang={props.pageContext.language}
			/>
			<TextContent
				title={titulo}
				subtitle={subtitulo}
				data={contenido.json}
				menu={menu}
				slug={slug}
			/>
		</Layout>
	)
}

export default Terms
